@use "style-variables" as var;

html,
body {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
}

* {
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 16px;
    opacity: 0.6;
  }

  &::-webkit-scrollbar-thumb:hover {
    transition: background 1s ease-in-out;
    background: #969696;
  }

  .mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
  }

  pre {
    font-size: 13px;
    margin: 4px 0;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

