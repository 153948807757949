@use "src/styles/style-variables" as var;
@use 'node_modules/@angular/material' as mat;

@mixin recrewt-button-style() {
  .mat-mini-fab:not(.round-fab),
  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    border-radius: 6px;

    &.big {
      padding: var.$nudge-amount * 0.5 var.$nudge-amount * 2;
    }

    &.jumbo {
      display: block;
      margin: var.$nudge-amount;
      padding: var.$nudge-amount var.$nudge-amount * 8;
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    padding: 0 8px;
    line-height: 30px;
  }

  .mat-button-toggle-group-appearance-standard {
    border: none;
    @include mat.elevation(1);
  }
  mat-button-toggle-group.gradient-checked {
    .mat-button-toggle-checked {
      @extend .gradient-bg;
    }
  }
}
