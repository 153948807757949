/* inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../assets/fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/inter-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-500 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('../assets/fonts/inter-v12-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/inter-v12-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('../assets/fonts/inter-v12-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/inter-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('../assets/fonts/inter-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/inter-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

//@font-face {
//    font-family: 'Material Icons';
//    font-style: normal;
//    font-weight: 400;
//    src: local('Material Icons'),
//    url(../assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
//}
//
//
//@font-face {
//    font-family: 'Material Icons Outlined';
//    font-style: normal;
//    font-weight: 400;
//    src: local('Material Icons Outlined'),
//    url(../assets/fonts/MaterialIconsOutlined-Regular.otf) format('opentype');
//}
//
//
//.mat-icons,
//.material-icons {
//    font-family: 'Material Icons';
//    font-weight: normal;
//    font-style: normal;
//    font-size: 24px;  /* Preferred icon size */
//    display: inline-block;
//    line-height: 1;
//    text-transform: none;
//    letter-spacing: normal;
//    word-wrap: normal;
//    white-space: nowrap;
//    direction: ltr;
//
//    /* Support for all WebKit browsers. */
//    -webkit-font-smoothing: antialiased;
//    /* Support for Safari and Chrome. */
//    text-rendering: optimizeLegibility;
//
//    /* Support for Firefox. */
//    -moz-osx-font-smoothing: grayscale;
//
//    /* Support for IE. */
//    font-feature-settings: 'liga';
//}
//
//
//.mat-icons-outlined,
//.material-icons-outlined {
//    font-family: 'Material Icons Outlined';
//    font-weight: normal;
//    font-style: normal;
//    font-size: 24px;  /* Preferred icon size */
//    display: inline-block;
//    line-height: 1;
//    text-transform: none;
//    letter-spacing: normal;
//    word-wrap: normal;
//    white-space: nowrap;
//    direction: ltr;
//
//    /* Support for all WebKit browsers. */
//    -webkit-font-smoothing: antialiased;
//    /* Support for Safari and Chrome. */
//    text-rendering: optimizeLegibility;
//
//    /* Support for Firefox. */
//    -moz-osx-font-smoothing: grayscale;
//
//    /* Support for IE. */
//    font-feature-settings: 'liga';
//}
