@use '../node_modules/@angular/material' as mat;
@use "./styles/style-variables" as var;
@use "./styles/palettes" as pal;


@import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";
@import "styles/fonts";

@import "./styles/style-reset";

@import "./styles/light-theme";
@import "./styles/typography";

@import "styles/margin";
@import "styles/border";
@import "styles/size";
@import "styles/text";

@import "styles/custom/button";
@import "styles/custom/form-field";
@import "styles/custom/expansion-panel";
@import "styles/custom/stepper";
@import "styles/custom/chip";

@include mat.core($recrewt-typography);

@mixin custom-components-theme() {
  @include recrewt-button-style();
  @include recrewt-form-field-style();
  @include recrewt-expansion-panel-style();
  @include recrewt-stepper-style();
  @include recrewt-chip-style();
}

.recrewt-light-theme {
  @include mat.all-component-themes($recrewt-company-suite-light-theme);
  @include custom-components-theme();
}

.primary-bg {
  background-color: var.$primary;
  color: var.$on-primary;
}

.primary-bg-medium {
  background-color: var.$primary-300;
  color: var.$on-primary;
}

.primary-bg-low {
  background-color: var.$primary-100;
  color: var.$text;
}

.primary {
  color: var.$primary;
}

.accent {
  color: var.$accent;
}

.accent-bg {
  background-color: var.$accent;
  color: var.$on-accent;
}

.accent-bg-low {
  background-color: var.$accent-100;
}

a {
  text-decoration: none;
  color: var.$accent;

  &:hover {
    color: var.$primary;
  }
}

.surface {
  background-color: white;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.on-primary {
  color: var.$on-primary;
}

.on-primary-medium {
  color: var.$on-primary;
  opacity: 0.74;
}

.on-primary-low {
  color: var.$on-primary;
  opacity: 0.38;
}

.on-surface {
  color: var.$text;
}

.on-surface-medium {
  color: var.$secondary-text;
}

.warn {
  color: var.$warn;
}

.err-box {
  @extend .b-rad-1;
  @extend .p-1;
  background-color: var.$warn-100;
}

.warn-box {
  @extend .b-rad-1;
  @extend .p-1;
  background-color: var.$primary-100;
}

.gradient-bg {
  background-image: linear-gradient(to bottom right, var.$primary, var.$accent);
  color: white;
}

.gradient-text-color {
  background: linear-gradient(to bottom right, var.$primary, var.$accent);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.text-shadow {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.54);
}

.green {
  color: rgb(77, 146, 33)
}

.pointer {
  cursor: pointer;
}

.hover:hover {
  background-color: map-get(pal.$recrewt-palette-background, hover);
}

/** Breadcrumbs
https://udayvunnam.github.io/xng-breadcrumb/#/custom-styles */


.xng-breadcrumb-root {
  font-size: mat.font-size($recrewt-typography, 'caption');

}

.xng-breadcrumb-list {

}

/** all breadcrumb types */
.xng-breadcrumb-item {
  padding: 0 4px;
  border-radius: 2px;
  background-color: mat.get-color-from-palette(mat.$light-theme-background-palette, 'hover');
}


.xng-breadcrumb-link {

}

.xng-breadcrumb-seperator {
  padding: 0 4px;
}

.xng-breadcrumb-trail {

}

mat-spinner.green circle {
  stroke: green !important;
}


/** EOF Breadcrumbs */
